<template>
  <page-layout-maintenance
    :animated="false"
    subtitle="We are currently undergoing maintenance. Please check back later."
    title="503"
    :type="MAINTENANCE_PAGE_TYPES.NOT_AUTHORIZED"
  >
    <template #action-button>
      <!-- Back home button -->
      <mi-btn color="accent" to="/"> Back Homepage</mi-btn>
    </template>
  </page-layout-maintenance>
</template>

<script>
  import { MAINTENANCE_PAGE_TYPES } from '@/constants'

  import PageLayoutMaintenance from '@/layouts/PageLayoutMaintenance.vue'

  export default {
    name: 'NotAllowed',
    components: { PageLayoutMaintenance },
    data: () => ({ MAINTENANCE_PAGE_TYPES })
  }
</script>
